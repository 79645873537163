export const phaseList = [
  { num: "00", name: "P0", id: "xxxx-xx-00" },
  { num: "01", name: "P1", id: "xxxx-xx-01" },
  { num: "02", name: "P2", id: "xxxx-xx-02" },
  { num: "03", name: "P3", id: "xxxx-xx-03" },
  { num: "04", name: "P4", id: "xxxx-xx-04" },
  { num: "05", name: "P5", id: "xxxx-xx-05" },
  { num: "06", name: "P6", id: "xxxx-xx-06" },
  { num: "07", name: "P7", id: "xxxx-xx-07" },
  { num: "08", name: "P8", id: "xxxx-xx-08" },
  { num: "09", name: "P9", id: "xxxx-xx-09" },
  { num: "10", name: "P10", id: "xxxx-xx-10" },
  { num: "11", name: "P11", id: "xxxx-xx-11" },
  { num: "12", name: "P12", id: "xxxx-xx-12" },
];

export const yearList = [
  { num: "25", year: "2025", id: "xxxx-25" },
  { num: "24", year: "2024", id: "xxxx-24" },
  { num: "23", year: "2023", id: "xxxx-23" },
];

export const stateList = [
  { name: "IL", id: "IL" },
  { name: "WI", id: "WI" },
  { name: "OH", id: "OH" },
];

export const pmList = [
  { name: "Everardo", id: 178 },
  { name: "Adrian", id: 177 },
  { name: "Nicholas", id: 137 },
  { name: "Max", id: 127 },
  { name: "Gabriel", id: 180 },
  { name: "Quinn", id: 0 },
];
